import {
  Content,
  ContentHeader,
  LinkButton,
  TableProps,
} from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { CatalogTableColumnsFunc } from '@backstage/plugin-catalog';
import {
  EntityListProvider,
  CatalogFilterLayout,
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityNamespacePicker,
  EntityOwnerPicker,
  EntityProcessingStatusPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { PageLayout } from 'tmna-ui-kit';
import CatalogSupportButton from './CatalogSupportButton';
import { Link } from 'react-router-dom';
import * as plugins from '../../../../plugins';
// import { CustomUserListPicker } from './CustomUserListPicker';
// import { CustomEntityTagPicker } from './CustomEntityTagPicker';
import {
  ANNOTATION_VIEW_URL,
  ANNOTATION_EDIT_URL,
} from '@backstage/catalog-model';
import { Box, withStyles, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GithubIcon from '@material-ui/icons/GitHub';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { CatalogTableRow } from '@backstage/plugin-catalog';
// import { CatalogTable } from '@backstage/plugin-catalog';
import { CatalogTable } from '../CatalogTable';
import { defaultCatalogTableColumnsFunc } from '../CatalogTable/defaultCatalogTableColumnsFunc';

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(StarIcon);

const useStyles = makeStyles({
  iconContainer: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const CatalogPage = () => {
  const classes = useStyles();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const registerComponentLink = useRouteRef(
    plugins.CatalogImport.routes.importPage,
  );

  const customColumnFunc: CatalogTableColumnsFunc = entityListContext => {
    const { filters } = entityListContext;
    const {
      createTitleColumn,
      createNameColumn,
      createSpecLifecycleColumn,
      createMetadataDescriptionColumn,
      createSpecTypeColumn,
    } = CatalogTable.columns;
    if (filters.kind?.value !== 'component')
      return defaultCatalogTableColumnsFunc(entityListContext);
    return [
      createTitleColumn({ hidden: true }),
      createNameColumn({
        defaultKind: filters.kind?.value,
        entityRefLinkProps: { hideIcon: true },
        tableOptions: { width: '25%' },
      }),

      {
        title: 'Owner',
        field: 'entity.metadata.appOwner',
        width: '10%',
      },
      createSpecLifecycleColumn({ tableOptions: { width: '5%' } }),
      createMetadataDescriptionColumn({ tableOptions: { width: '40%' } }),
      createSpecTypeColumn({ tableOptions: { width: '20%' } }),
    ];
  };

  const actions: TableProps<CatalogTableRow>['actions'] = [
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
      const title = 'View';

      return {
        icon: () => (
          <Box className={classes.iconContainer}>
            <GithubIcon aria-label={title} fontSize="small" />
          </Box>
        ),
        tooltip: title,
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
      const title = 'Edit';

      return {
        icon: () => (
          <Box className={classes.iconContainer}>
            <EditIcon aria-label={title} fontSize="small" />
          </Box>
        ),
        tooltip: title,
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      const title = isStarred ? 'Remove from favorites' : 'Add to favorites';

      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => (
          <Box className={classes.iconContainer}>
            {isStarred ? <YellowStar /> : <StarBorderIcon />}
          </Box>
        ),
        tooltip: title,
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  return (
    <PageLayout
      title="App Catalog"
      subtitle="Centralized register for all software in TMNA"
      headerBackgroundImg="/assets/service-catalog_bg.png"
      displayButton
    >
      <Content>
        <ContentHeader title="">
          {registerComponentLink && (
            <LinkButton
              component={Link}
              variant="contained"
              color="primary"
              to={registerComponentLink()}
            >
              Register Existing Component
            </LinkButton>
          )}
          <CatalogSupportButton />
        </ContentHeader>
        <EntityListProvider pagination={{ limit: 10 }}>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityKindPicker />
              <EntityTypePicker />
              <UserListPicker
                initialFilter="all"
                availableFilters={['all', 'starred']}
              />
              {/* <CustomUserListPicker
                initialFilter="all"
                availableFilters={['all', 'starred']}
              /> */}
              <EntityOwnerPicker />
              <EntityLifecyclePicker />
              <EntityTagPicker />
              <EntityProcessingStatusPicker />
              <EntityNamespacePicker />
              {/* <CustomEntityTagPicker /> */}
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable
                columns={customColumnFunc}
                actions={actions}
                tableOptions={{ pageSize: 10, pageSizeOptions: [10, 20, 30] }}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageLayout>
  );
};
