import React from 'react';
import { Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  SupportButton,
  SupportTextList,
  SupportLinks,
  SupportMultipleLinkCard,
} from 'tmna-ui-kit';

const RELATED_DOCS_SUPPORT_LINKS = [
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/introduction/',
    title: 'What is App Catalog?',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/ecosystem-modeling/',
    title: 'App Catalog Ecosystem',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/component-types/',
    title: 'Component Types',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/navigating-catalog/',
    title: 'Navigating App Catalog',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/features/repo-assocations/',
    title: 'Associating Subcomponents',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/definitions/yaml-file/',
    title: 'YAML File Fields',
  },
  {
    to: '/docs/default/Component/ACE-TECH-DOCS/catalog/contact-us/',
    title: 'Contact Us',
  }
];

export const CatalogSupportButton = () => {
  return (
    <SupportButton>
      <SupportTextList>
        <Typography variant="body2">
          Centralized register that provides ownership and metadata related
          information for all software in TMNA.
        </Typography>
      </SupportTextList>
      <SupportLinks>
        <SupportMultipleLinkCard
          icon={<DescriptionIcon />}
          title="Related Docs"
          items={RELATED_DOCS_SUPPORT_LINKS}
        />
      </SupportLinks>
    </SupportButton>
  );
};

export default CatalogSupportButton;
